@import "../utilities/styles.scss";

.people {
  overflow: hidden;
  margin: 60px auto 60px auto;
  max-width: 1000px;

  .people-title {
    font-family: "Lora", serif;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .stack {
    text-align: center;
    margin: 30px auto 60px auto;
    font-family: "Lora", serif;
    display: flex;
    justify-content: center;

    .action {
      margin: auto;
    }
  }

  .member-align {
    display: flex;
    justify-content: flex-start;

    .people-text {
      color: $grey3;
      font-size: 16px;
      margin-top: 20px;
      font-family: "Noto Sans", sans-serif;
      line-height: 1.6;
    }
  }

  .people-subtitle {
    font-family: "Lora", serif;
    font-size: 22px;
    font-weight: 500;
  }

  a {
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    color: #393a3d;
  }
}

@media only screen and (max-width: 600px) {
  .people {
    margin: 60px auto 30px auto;
    padding: 0px 20px;

    .people-title,
    .people-subtitle {
      font-size: 18px;
    }

    .stack {
      display: flex;
      flex-direction: column;
    }

    .member-align {
      .people-text {
        max-width: fit-content;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
      }
    }
  }
}