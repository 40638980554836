@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

.container {
  align-content: center;
  height: 90vh;
  background-image: url("../images/option3.jpg");
  background-size: cover;
  background-blend-mode: saturation;
  animation-duration: 1s;
  position: relative;

  .content {
    position: absolute;
    top: 360px;
    text-align: left;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
    padding: 20px;

    .header {
      font-size: 30px;
      font-family: "Lora", serif;
      color: antiquewhite;
    }

    .typewritter {
      display: block;
      font-size: 55px;
      font-family: "Lora", serif;
      color: antiquewhite;
      font-weight: bold;
    }
  }

  .join-button {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0px 20px;

    .content {
      top: 280px;

      .header {
        font-size: 20px;
      }

      .typewritter {
        font-size: 32px;
      }
    }
  }
}