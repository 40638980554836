@import "../utilities/styles.scss";

.newsletter {
  overflow: hidden;
  max-width: 1000px;
  margin: 60px auto 60px auto;

  .news-title {
    font-family: "Lora", serif;
    font-size: 30px;
    font-weight: 600;
  }

  .news-card {
    padding-top: 30px;
    font-size: 16px;
    line-height: 1.6;
    font-family: "Noto Sans", sans-serif;
    display: flex;
    justify-content: space-between;
  }

  .note {
    color: $grey1;
    margin-top: 28px;
    text-align: center;
  }

  .meetup {
    align-items: center;
    width: 400px;
    margin-left: 50px;
    margin-top: 150px;
    display: inline-block;
  }
}

@media only screen and (max-width: 600px) {
  .newsletter {
    margin: 60px auto 30px auto;
    padding: 0px 20px;
    font-family: "Noto Sans", sans-serif;

    .news-title {
      font-size: 18px;
    }

    .note {
      font-size: 14px;
    }

    .news-card {
      display: flex;
      flex-direction: column;
    }
  }
}