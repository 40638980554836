@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import "../utilities/styles.scss";

.intro {
  overflow: hidden;
  padding: 0px 10px;
  margin: 120px auto 60px auto;
  max-width: 1000px;

  .about-title {
    font-family: "Lora", serif;
    font-size: 30px;
    font-weight: 600;
  }

  .introContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textbox {
      width: 500px;
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;


      .answer {
        padding-top: 20px;
        line-height: 1.6;
        color: $grey3;
      }

      a {
        font-weight: 600;
        color: #393a3d;
      }
    }

    .graph {
      width: 400px;
      height: auto;
      padding-top: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {

  .intro {
    margin: 60px auto 30px auto;
    padding: 0px 20px;

    .about-title {
      font-size: 18px;
    }

    .introContent {
      display: flex;
      flex-direction: column;
      height: 720px;

      .textbox {
        max-width: fit-content;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;

        .answer {
          padding-top: 20px;
          line-height: 1.6;
          color: $grey3;
        }

        a {
          font-weight: 600;
          color: #393a3d;
        }
      }

      .graph {
        align-self: center;
        max-width: 360px;
        padding-top: 0px;
      }
    }
  }
}