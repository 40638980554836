@import "../utilities/styles.scss";

.nav-wrapper {
  overflow: hidden;
  background-color: #001103;

  .nav {
    display: flex;
    max-width: 1000px;
    margin: auto;
    justify-content: space-between;
    z-index: 2;
    padding: 20px 0px;

    .nav-lefttext {
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      width: 150px;
      color: #c0c0c0;
      font-family: "Noto Sans", sans-serif;
      transition: $fast-transition;

      &:hover {
        color: #f5f5f5;
      }
    }

    .nav-righttext {
      cursor: pointer;
      font-size: 18px;
      width: 150px;
      margin-left: 20px;
      transition: $fast-transition;
    }

    a {
      color: #c0c0c0;
      font-family: "Noto Sans", sans-serif;
      text-decoration: none;
      font-size: 15px;

      &:hover {
        color: #f5f5f5;
        font-weight: 600;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .nav-wrapper {
    padding: 0px 20px;

    .nav {

      .nav-lefttext,
      .nav-righttext {
        font-size: 14px;
      }
    }
  }
}