.news {
  position: relative;
  max-width: 1000px;
  z-index: 2;
  font-size: 20px;
  font-weight: 600px;
  margin: auto auto 40px auto;
  padding: 30px 0px 60px 20px;
  border-bottom: 1px solid #ccc;
  font-family: "Noto Sans", sans-serif;
  list-style-type: none;
}

.title {
  margin-left: 80px;
  margin-top: 40px;
}

.section-title {
  font-size: 1rem;
  margin-top: 30px;
  margin-bottom: 5px;
  margin-left: 80px;
}

.section-item {
  margin-bottom: 5px;
  margin-left: 80px;
}

.section-item a {
  color: blue;
}

@media only screen and (max-width: 600px) {
  .news {
    padding: 0px 20px;
    font-size: 14px;

    .title,
    .section-title,
    .section-item {
      margin-left: 0px;
    }

  }
}