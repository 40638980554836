@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import "./utilities/styles";

.App {
  height: 100%;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.homepage {
  background-color: $page-background;
  //color: $grey1;
}

.return {
  position: relative;
  width: 100px;
  padding: 15px 0px 15px 20px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
}

.return:hover {
  background-color: #0d98ba;
  color: white;
  transition: 0.5s;
}

.joinlink {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  .joinlink:hover {
    text-decoration: underline;
  }
}