@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.join {
  overflow: hidden;
  max-width: 1000px;
  margin: 60px auto 60px auto;

  .join-context {


    .community {
      font-family: "Lora", serif;
      font-size: 30px;
      font-weight: 600;
    }

    .contact {
      margin: 10px 0px 25px 0px;
      font-size: 18px;

      .email,
      .slack {
        font-family: "Noto Sans", sans-serif;
      }
    }


    .footer {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }


  a {
    color: grey;
    font-family: "Noto Sans", sans-serif;
    text-decoration: none;
    font-size: 14px;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: black;
  }

}

@media only screen and (max-width: 600px) {
  .join {
    margin: auto;

    .context {
      margin-left: 10px;

      .community {
        margin-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .join {
    margin: 60px auto 30px auto;
    padding: 0px 20px;

    .join-context {
      .community {
        font-size: 18px;
      }

      span,
      a {
        max-width: fit-content;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
      }
    }
  }
}